export const dateFormat = {
  default: 'MMM DD, YYYY hh:mm A', // March 17th, 2022 14:45:30 PM
};

export const service = {
  collection: 'collection',
  accountMgt: 'accountMgt',
  commercePortal: 'commercePortal',
  deviceCache: 'deviceCache',
  deviceShadow: 'deviceShadow',
  fleetMgt: 'fleetMgt',
  programMgt: 'programMgt',
  solutionRegistry: 'solutionRegistry',
  subscriptions: 'subscriptions',
  telemetry: 'telemetry',
};

export const store = {
  details: {
    START_ROOT_COMPONENT: 'details/startRootComponent',
    DEVICE_ID: 'details/deviceId',
    DEVICE_MODEL_NUMBER: 'details/deviceModelNumber',
    DEVICE_SERIAL_NUMBER: 'details/deviceSerialNumber',
    DEVICE_GROUP: 'details/deviceGroup',
    IS_FROM_UNASSIGNED: 'details/isFromUnassigned',
    IS_PARTNER_LINK_SUPPORTED: 'details/isPartnerLinkSupported',
    IS_MULTIPLE_TAB: 'details/isMutipleTab',
    PARTNER_LINK_LIST: 'details/partnerLinkList',
    ENTITLEMENTS: 'details/entitlements',
    CONSUMABLES: 'details/consumables',
    DEVICE_INFO: 'details/deviceInfo',
    DMS_LIST: 'details/dmsList',
    SHOW_JOBS_TAB: 'details/showJobsTab',
  },
  // TBD[RnPm] : After deciding Role and Permission, It must be updated
  // Sample Role and Permission
  detailsRnPm: {
    STORE_PERMISSION_FEATURES_CONSTRAINTS_READ: 'detailsRnPm/featuresConstraintsRead',
    STORE_PERMISSION_SUPPLIES_READ: 'detailsRnPm/suppliesRead',
    STORE_PERMISSION_DEVICES_MEDIA_READ: 'detailsRnPm/devicesMediaRead',
    STORE_PERMISSION_POLICY_READ: 'detailsRnPm/policyRead',
    STORE_PERMISSION_POLICY_LIST: 'detailsRnPm/policyList',
    STORE_PERMISSION_JOB_HISTORY_READ: 'detailsRnPm/jobHistoryRead',
  },
};

// TBD[RnPm] : After deciding Role and Permission, It must be updated
// Sample Role and Permission
export const permission = {
  features: {
    constraint: {
      READ: 'ws-hp.com/constraintsEngine/features.constraints.READ',
    },
  },
  supplies: {
    READ: 'ws-hp.com/suppliesdocument/supplies.READ',
  },
  devices: {
    media: {
      READ: 'ws-hp.com/devicedocument/devices.media.READ',
    },
  },
  policy: {
    READ: 'ws-hp.com/fleet/policy.READ',
    LIST: 'ws-hp.com/fleet/policy.LIST',
  },
  job: {
    history: {
      READ: 'ws-hp.com/telemetry/job.history.READ',
    },
  },
};
export const origin = {
  ECP_LOCAL: 'http://ecp.local',
  DEV: 'https://ecp.dev.portalshell.int.hp.com',
  PIE: 'https://ecp.pie.portalshell.int.hp.com',
  PIE_WEX: 'https://usdevms-workforce.hppipeline.com',
  STAGE: 'https://ecp.stage.portalshell.int.hp.com',
  STAGE_WEX: 'https://usstagingms.workforceexperience.hp.com',
  PROD: 'https://hp-commandcenter.com',
};

export const CURRENT_STACK = {
  DEV: window.location.origin === origin.DEV,
  LOCAL: window.location.origin.includes(origin.ECP_LOCAL),
  PIE: window.location.origin === origin.PIE,
  PIE_WEX: window.location.origin === origin.PIE_WEX,
  STAGE: window.location.origin === origin.STAGE,
  STAGE_WEX: window.location.origin === origin.STAGE_WEX,
  PROD: window.location.origin === origin.PROD,
};

export const COLOR_CODE = {
  K: `#000000`,
  C: `#009ace`,
  M: `#e31c79`,
  Y: `#ffd100`,
};

export const BORDER = {
  solidOrange: `1px solid #d06702`,
  redDotted: `1px dashed #be1313`,
};

export const LABEL = {
  white: '#ffffff',
  black: '#000000',
};

export const mockDmsList = [
  ' Lorem ipsum dolor sit amet consectetur. Interdum id porttitor iaculis lacus mi sapien. Quis lorem vitae justo nibh elementum tristique pharetra convallis. Et risus diam congue morbi quam semper pellentesque sed vel. Nibh euismod pellentesque consequat eget ipsum pretium nam elementum vivamus. Nunc sollicitudin in urna laoreet. Adipiscing elementum eget pulvinar pretium. Sollicitudin amet tincidunt volutpat quis vel dictumst eget enim viverra. Quam vel tellus tortor libero. A et commodo iaculis orci ullamcorper ac auctor. At proin netus nulla semper feugiat cursus nec. Faucibus interdum volutpat scelerisque fermentum feugiat et viverra nunc. Nulla erat leo dolor mattis adipiscing sem euismod. Turpis tortor egestas ultrices tincidunt. Ultrices nulla facilisi mauris dui ornare. Sit risus diam scelerisque amet nunc viverra sit. In eget placerat malesuada malesuada sagittis. Proin aenean in ac porttitor diam eu sit. In eu condimentum et facilisis vulputate nulla ultricies risus. Sed id semper imperdiet pretium viverra. Porta aenean morbi molestie adipiscing morbi feugiat tellus turpis hendrerit. Praesent duis phasellus',
  ' Lorem ipsum dolor sit amet consectetur. Interdum id porttitor iaculis lacus mi sapien. Quis lorem vitae justo nibh elementum tristique pharetra convallis. Et risus diam congue morbi quam semper pellentesque sed vel. Nibh euismod pellentesque consequat eget ipsum pretium nam elementum vivamus. Nunc sollicitudin in urna laoreet. Adipiscing elementum eget pulvinar pretium. Sollicitudin amet tincidunt volutpat quis vel dictumst eget enim viverra. Quam vel tellus tortor libero. A et commodo iaculis orci ullamcorper ac auctor. At proin netus nulla semper feugiat cursus nec. Faucibus interdum volutpat scelerisque fermentum feugiat et viverra nunc. Nulla erat leo dolor mattis adipiscing sem euismod. Turpis tortor egestas ultrices tincidunt. Ultrices nulla facilisi mauris dui ornare. Sit risus diam scelerisque amet nunc viverra sit. In eget placerat malesuada malesuada sagittis. Proin aenean in ac porttitor diam eu sit. In eu condimentum et facilisis vulputate nulla ultricies risus. Sed id semper imperdiet pretium viverra. Porta aenean morbi molestie adipiscing morbi feugiat tellus turpis hendrerit. Praesent duis phasellus',
  ' Lorem ipsum dolor sit amet consectetur. Interdum id porttitor iaculis lacus mi sapien. Quis lorem vitae justo nibh elementum tristique pharetra convallis. Et risus diam congue morbi quam semper pellentesque sed vel. Nibh euismod pellentesque consequat eget ipsum pretium nam elementum vivamus. Nunc sollicitudin in urna laoreet. Adipiscing elementum eget pulvinar pretium. Sollicitudin amet tincidunt volutpat quis vel dictumst eget enim viverra. Quam vel tellus tortor libero. A et commodo iaculis orci ullamcorper ac auctor. At proin netus nulla semper feugiat cursus nec. Faucibus interdum volutpat scelerisque fermentum feugiat et viverra nunc. Nulla erat leo dolor mattis adipiscing sem euismod. Turpis tortor egestas ultrices tincidunt. Ultrices nulla facilisi mauris dui ornare. Sit risus diam scelerisque amet nunc viverra sit. In eget placerat malesuada malesuada sagittis. Proin aenean in ac porttitor diam eu sit. In eu condimentum et facilisis vulputate nulla ultricies risus. Sed id semper imperdiet pretium viverra. Porta aenean morbi molestie adipiscing morbi feugiat tellus turpis hendrerit. Praesent duis phasellus',
  ' Lorem ipsum dolor sit amet consectetur. Interdum id porttitor iaculis lacus mi sapien. Quis lorem vitae justo nibh elementum tristique pharetra convallis. Et risus diam congue morbi quam semper pellentesque sed vel. Nibh euismod pellentesque consequat eget ipsum pretium nam elementum vivamus. Nunc sollicitudin in urna laoreet. Adipiscing elementum eget pulvinar pretium. Sollicitudin amet tincidunt volutpat quis vel dictumst eget enim viverra. Quam vel tellus tortor libero. A et commodo iaculis orci ullamcorper ac auctor. At proin netus nulla semper feugiat cursus nec. Faucibus interdum volutpat scelerisque fermentum feugiat et viverra nunc. Nulla erat leo dolor mattis adipiscing sem euismod. Turpis tortor egestas ultrices tincidunt. Ultrices nulla facilisi mauris dui ornare. Sit risus diam scelerisque amet nunc viverra sit. In eget placerat malesuada malesuada sagittis. Proin aenean in ac porttitor diam eu sit. In eu condimentum et facilisis vulputate nulla ultricies risus. Sed id semper imperdiet pretium viverra. Porta aenean morbi molestie adipiscing morbi feugiat tellus turpis hendrerit. Praesent duis phasellus',
];
