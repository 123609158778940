import styled from 'styled-components';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { mockDmsList } from 'src/utils/constants';
import { collectionAPI } from 'src/api/collection';
import { useStoreState } from 'src/store/useStoreState';
import { deviceCacheAPI } from 'src/api/deviceCache';
import { subscriptionsAPI } from 'src/api/subscriptions';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { DetailsMainTemplate } from 'src/components/template';
import { t, refreshRootComponent } from 'src/utils/commonMethods';
import { EmptyContainer, CommonLoading } from 'src/components/atom';
import {
  storeDmsList,
  storeDeviceInfo,
  storeDeviceGroup,
  storeConsumables,
  storeEntitlements,
  storeIsMultipleTab,
  storePartnerLinkList,
  storeDeviceModelNumber,
  storeDeviceSerialNumber,
  storeIsPartnerLinkSupported,
  storeShowJobsTab,
} from 'src/store/details/action';
import { deviceShadowAPI } from 'src/api/deviceShadow';
import { useIdleTimer } from 'react-idle-timer';

export const DetailsMainPage = (props) => {
  const { detailsState } = useStoreState();
  const { deviceId } = detailsState;
  const { useToast, ecpDeviceV55, isWex } = useShellRootContext();
  const { getDeviceInfo } = deviceCacheAPI();
  const { getCollectionByEntityId } = collectionAPI();
  const { getSubscriptions } = subscriptionsAPI();
  const { getDeviceDiscoveryWithResourceName } = deviceShadowAPI();
  const [details, setDetails] = useState(null);
  const [error, setError] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [inkWarnings, setInkWarnings] = useState([]);
  const dispatch = useDispatch();
  const [silentRefresh, setSilentRefresh] = useState(false);

  useEffect(() => {
    fetchAPI().then();
  }, []);

  useEffect(() => {
    if (error) {
      renderError();
    }
  }, [error]);

  const onIdle = () => {
    setSilentRefresh(true);
  };
  const idleTimer = useIdleTimer({ onIdle, timeout: 1000 * 60 * 5 }); //5min
  const handleSilentRefresh = async () => {
    await apiGetDevice();
    await apiGetDeviceStatusMessages();
    await apiGetGroupName();
    idleTimer.reset();
    setSilentRefresh(false);
  };

  useEffect(() => {
    if (silentRefresh) {
      handleSilentRefresh();
    }
  }, [silentRefresh]);

  const fetchAPI = async () => {
    setIsFetching(true);
    await apiGetDevice();
    await apiGetDeviceStatusMessages();
    await apiGetGroupName();
    setIsFetching(false);
  };

  const isPartnerLinkSupported = (solutions) => {
    return solutions.some((serviceId) => serviceId === 'ws-hp.com/partnerlinksvc');
  };

  const enableMultipleTabs = (supplies, mediaTypes) => {
    if (supplies.consumables === null) {
      return false;
    }

    if (
      supplies.consumables.ink.length > 0 &&
      supplies.consumables.otherSupplies.length == 0 &&
      supplies.consumables.printHead.length == 0
    ) {
      return false;
    }

    if (
      supplies.consumables.otherSupplies.length == 0 ||
      supplies.consumables.printHead.length == 0
    ) {
      return true;
    }
    /* istanbul ignore next */
    return mediaTypes.inputs?.media.length > 0;
  };

  const getCombinedSupplies = (supplies, mediaTypes) => {
    /* istanbul ignore next */
    return {
      ink: supplies?.ink ?? [],
      printHead: supplies?.printHead,
      otherSupplies: supplies?.otherSupplies,
      media: mediaTypes?.inputs?.media,
    };
  };

  const apiGetDevice = async () => {
    console.log('[details] apiGetDevice');

    const details = await getDeviceInfo(deviceId);
    // const deviceDiscovery = await deviceShadowSvc.getDeviceDiscoveryWithResourceName(
    //   deviceShadowClient,
    //   deviceId,
    //   'jobManagement_jobs',
    // );

    if (details.error !== undefined) {
      setError(true);
      return;
    }

    setDetails(details.response);

    // if (deviceDiscovery.error !== undefined) {
    //   setJobsTabSupported(false);
    // } else setJobsTabSupported(true);

    const { supplies, mediaTypes, solutions, identity } = details.response;
    const showMultipleTab = enableMultipleTabs(supplies, details.response.mediaTypes);
    const combinedSupplies = getCombinedSupplies(supplies.consumables, mediaTypes);
    const enablePartnerLink = isPartnerLinkSupported(solutions);

    dispatch(storeIsMultipleTab(showMultipleTab));
    dispatch(storeIsPartnerLinkSupported(enablePartnerLink));

    dispatch(storeConsumables(combinedSupplies));
    dispatch(storeEntitlements(solutions));
    dispatch(storeDeviceModelNumber(identity.makeAndModel.number));
    dispatch(storeDeviceSerialNumber(identity.serialNumber));
    dispatch(storeDeviceInfo(details.response));

    enableInkWarnings(combinedSupplies.ink);

    if (ecpDeviceV55 && enablePartnerLink) {
      await apiGetPartnerLink(identity.makeAndModel.number, identity.serialNumber);
    }

    if (
      !isWex &&
      details.response.connTypes != undefined &&
      details.response.connTypes.includes('print-iot')
    ) {
      await apiGetDeviceDiscovery();
    }
  };

  const enableInkWarnings = (inks) => {
    if (!inks || inks.length <= 0) {
      return;
    }

    const warnings = [];
    inks.forEach((ink) => {
      if (ink.stateReason.includes('dynamicIntegrityViolationError')) {
        warnings.push(ink.color);
      }
    });
    setInkWarnings(warnings);
  };

  const apiGetGroupName = async () => {
    console.log('[details] apiGetGroupName');

    const { response, error } = await getCollectionByEntityId(deviceId);

    if (error) {
      dispatch(storeDeviceGroup(null));
    } else {
      dispatch(storeDeviceGroup(response[0].name));
    }
  };

  const apiGetPartnerLink = async (productNumber, serialNumber) => {
    console.log('[details] apiGetPartnerLink');

    const partnerLink = await getSubscriptions(productNumber, serialNumber, deviceId);

    if (partnerLink.error !== undefined) {
      setError(true);
    } else {
      dispatch(storePartnerLinkList(partnerLink.response));
    }
  };

  const apiGetDeviceDiscovery = async () => {
    console.log('[details] apiGetDeviceDiscovery');
    const res = await getDeviceDiscoveryWithResourceName(deviceId, 'jobManagement_queueStatsItem');
    if (res.error != undefined) {
      dispatch(storeShowJobsTab(false));
    } else {
      dispatch(storeShowJobsTab(true));
    }
  };

  const apiGetDeviceStatusMessages = async () => {
    //Todo: Implement this w. @jarvis/web-device-message

    // setTimeout(() => {
    dispatch(storeDmsList(mockDmsList));
    // }, 5000);
  };

  const renderError = () => {
    useToast.addToast({
      id: 'error',
      type: 'negative',
      text: t('message.error'),
      action: (
        <a
          data-testid="error-retry"
          onClick={() => {
            refreshRootComponent(dispatch);
          }}
        >
          {t('label.retry')}
        </a>
      ),
    });
  };

  const enableCondition = !isFetching && details !== null && deviceId !== '';

  const memoRenderComponent = useMemo(() => {
    if (error) {
      return <EmptyContainer retryAction={fetchAPI} />;
    }

    if (enableCondition && silentRefresh == false) {
      console.log('[details] render <DetailsMain />');

      return (
        <DetailsMainTemplate
          {...props}
          {...{
            details,
            deviceId,
            inkWarnings,
            silentRefresh,
          }}
        />
      );
    }

    return <></>;
  }, [enableCondition, error]);

  return (
    <Wrapper data-testid="details-main-container">
      {isFetching ? <CommonLoading /> : <>{memoRenderComponent}</>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 99%;
  margin-bottom: 30px;
`;
